import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { Helmet } from 'react-helmet'

import { Link } from 'i18n/Link'
import Slider from 'components/ui-kit/Slider'
import SvgClose from 'assets/icons/svgClose.svg'
import { breakpoints } from 'components/ui-kit/globalValue'
import { useTranslations } from 'i18n/useTranslations'

const Modal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

const ButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 3;
`

const SvgCloseStyled = styled(SvgClose)`
    background-color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    fill: #333;
    @media (min-width: ${breakpoints.tablet}) {
        height: 50px;
        width: 50px;
    }
`

const CloseButton = styled(Link)``

const ProductImages = ({ data }) => {
    const translations = useTranslations()
    return (
        <ModalRoutingContext>
            {({ modal, closeTo }) => (
                <Modal>
                    <Helmet>
                        <title>
                            {translations.image} - {translations.logo} -
                            {translations.siteTitle}
                        </title>
                        <meta
                            name="description"
                            content="СПСК - Скала-подільський Спецкарʼєр. Фото продукція"
                        />
                    </Helmet>
                    <ButtonContainer>
                        <CloseButton aria-label="Закрити" to={closeTo}>
                            <SvgCloseStyled />
                        </CloseButton>
                    </ButtonContainer>
                    <Slider images={data.allFile.edges} />
                </Modal>
            )}
        </ModalRoutingContext>
    )
}

export default ProductImages

export const query = graphql`
    query {
        allFile(filter: { relativeDirectory: { eq: "products" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid(
                            maxWidth: 1200
                            maxHeight: 900
                            cropFocus: CENTER
                        ) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`
